import request from '@/utils/request'

export function getMailGroupList() {
    return request({
        url: '/mailgroup/getlist',
        method: 'get'
    })
}

export function updateMailGroup(mailGroupData) {
    return request({
        url: '/mailgroup/updatedata',
        method: 'post',
        data: mailGroupData
    })
}

export function deleteMailGroup(dataID) {
    return request({
        url: '/mailgroup/delete',
        method: 'post',
        data: dataID
    })
}
<style>
.dialog-info-label {
  display: inline-block;
  width: 340px;
}
</style>

<template>
  <div class="app-container">
    <!-- Begin Dialogs -->

    <!-- Dialog Get Mail Information -->
    <el-dialog
      title="Specify information for mail"
      :visible.sync="dialogTagInfoVisible"
      width="30%"
    >
      <el-form ref="form" label-width="140px">
        <el-form-item label="Tag Name:">
          {{ TagInfoName }}
        </el-form-item>

        <template
          v-if="TagInfoName === 'EventIntro' || TagInfoName === 'EventTeasing'"
        >
          <el-form-item label="Event Start:">
            <el-date-picker
              v-model="TagInfoStartDate"
              type="datetime"
              placeholder="Select date and time"
              @change="OnChangeInformationBeginDate($event)"
              style="width: 65%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Tracking Name:">
            <el-input
              v-model="TagInfoTrackingName"
              class="edit-input"
              placeholder="Input: (a-z), (0-9), _"
            />
          </el-form-item>

          <el-form-item label="Tracking Period:">
            <el-input
              v-model="TagInfoTrackingPeriod"
              class="edit-input"
              style="width: 65%"
            />
            (in seconds)
          </el-form-item>
        </template>

        <el-form-item>
          <el-button @click="OnInformationCancel()">Cancel</el-button>
          <el-button type="primary" @click="OnInformationConfirm()"
            >Confirm</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- End Dialogs -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" value="2">
      <el-tab-pane label="Send Mail" name="1">
        <el-form ref="form" :model="mail" label-width="120px">
          <!-- {{debugLog}} -->
          <el-form-item label="From">
            <b>{{ mail.from }}</b>
          </el-form-item>

          <el-form-item label="To user">
            <el-input
              v-model="mail.user_id"
              class="edit-input"
              type="textarea"
            />
          </el-form-item>

          <el-form-item label="Title">
            <el-input
              v-model="mail.title"
              class="edit-input"
              placeholder='Input=text or Input=${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Content">
            <el-input
              type="textarea"
              v-model="mail.content"
              class="edit-input"
              placeholder='Input=text or Input= ${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Begin Time">
            <el-date-picker
              v-model="mail.date_begin"
              type="datetime"
              placeholder="Select date and time"
              @change="OnChangeBeginDate($event)"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="End Time">
            <el-date-picker
              v-model="mail.date_end"
              type="datetime"
              placeholder="Select date and time"
              @change="OnChangeEndDate($event)"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="Reward"></el-form-item>
          <div style="padding-left: 120px; padding-bottom: 20px">
            <add-reward v-model="mail.rewards" />
          </div>

          <!-- Attach Mail Information -->
          <el-form-item label="Information">
            <el-input
              v-model="mail.info"
              class="edit-input"
              placeholder='Input={"Tag": "EventIntro","EventID": "20210908","StartDate":123456}'
              style="width: 46%"
            />

            <span> Auto Fill by </span>
            <el-select
              v-model="information_AutoFill"
              value-key="information_AutoFill"
              filterable
              allow-create
              placeholder="Select"
              @change="onInformationAutoFillChange($event)"
              style="width: 12%"
            >
              <el-option
                v-for="item in information_AutoFill_List"
                :key="item.InfoID"
                :label="item.InfoID"
                :value="item.InfoID"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">Send</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    
      <el-tab-pane label="Send Group" name="3">
        <el-form ref="form" :model="mailgroup" label-width="120px">
          <el-form-item label="From">
            <b>{{ mailgroup.creator }}</b>
          </el-form-item>

          <el-form-item label="Title">
            <el-input
              v-model="mailgroup.title"
              class="edit-input"
              placeholder='Input=text or Input= ${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Content">
            <el-input
              type="textarea"
              :rows="2"
              v-model="mailgroup.content"
              class="edit-input"
              placeholder='Input=text or Input= ${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Begin Time">
            <el-date-picker
              v-model="mailgroup.date_begin"
              type="datetime"
              placeholder="Select date and time"
              @change="OnChangeGroupBeginDate($event)"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="End Time">
            <el-date-picker
              v-model="mailgroup.date_end"
              type="datetime"
              placeholder="Select date and time"
              @change="OnChangeGroupEndDate($event)"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="Reward">
          </el-form-item>
          <div style="padding-left: 120px; padding-bottom: 20px">
            <add-reward v-model="mailgroup.rewards" />
          </div>

          <el-form-item label="User Filters">
            <!--{{ mailgroup.user_filters }}-->
          </el-form-item>
          <div style="padding-left: 120px; padding-bottom: 20px">
            <user-filter
              v-on:CallbackFillterData="CallbackFillterData"
            ></user-filter>
          </div>

          <!-- Attach Mail Information -->
          <el-form-item label="Information">
            <el-input
              v-model="mailgroup.info"
              class="edit-input"
              placeholder='Input={"Tag": "EventIntro","EventID": "20210908","StartDate":123456}'
              style="width: 46%"
            />

            <span> Auto Fill by </span>
            <el-select
              v-model="information_AutoFill"
              value-key="information_AutoFill"
              filterable
              allow-create
              placeholder="Select"
              @change="onInformationAutoFillChange($event)"
              style="width: 12%"
            >
              <el-option
                v-for="item in information_AutoFill_List"
                :key="item.InfoID"
                :label="item.InfoID"
                :value="item.InfoID"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmitGroup">Add</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Send Group List" name="4">
        <el-table :data="mailGroupDisplayList" style="width: 100%">
          <el-table-column label="ID" width="50px">
            <template slot-scope="{ row }">
              <span>{{ row.id }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Title" width="350px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input v-model="row.title" class="edit-input" size="small" />
              </template>
              <span v-else>{{ row.title }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Content" width="100px" type="expand">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input
                  v-model="row.content"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else>{{ row.content }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Information" width="150px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input v-model="row.info" class="edit-input" size="small" />
              </template>
              <span v-else>{{ row.info }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Date" width="210px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                From:
                <el-date-picker
                  v-model="row.date_begin"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                >
                </el-date-picker>
                <br /><br />
                To:
                <el-date-picker
                  v-model="row.date_end"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                >
                </el-date-picker>
              </template>
              <span v-else
                >From: {{ row.date_begin.toLocaleString("en-GB", { hour12: false }) }} <br /><br />
                To: {{ row.date_end.toLocaleString("en-GB", { hour12: false }) }}</span
              >
              <br />
            </template>
          </el-table-column>

          <el-table-column label="Rewards" width="420px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <edit-reward-list
                  v-bind:propID="row.id"
                  v-bind:propData="row.rewardData"
                  v-bind:propEditMode="row.editMode"
                  v-on:CallbackRewadListChangeData="CallbackRewadListChangeData"
                ></edit-reward-list>
              </template>
              <span v-else>
                <edit-reward-list
                  v-bind:propID="row.id"
                  v-bind:propData="row.rewardData"
                  v-bind:propEditMode="row.editMode"
                  v-on:CallbackRewadListChangeData="CallbackRewadListChangeData"
                ></edit-reward-list>
              </span>
            </template>
          </el-table-column>

          <el-table-column label="Filters" width="420px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <edit-reward-list
                  v-bind:propData="row.filterData"
                  v-bind:propEditMode="row.editMode"
                ></edit-reward-list>
              </template>
              <span v-else>
                <edit-reward-list
                  v-bind:propData="row.filterData"
                  v-bind:propEditMode="row.editMode"
                ></edit-reward-list>
              </span>
            </template>
          </el-table-column>

          <el-table-column label="Actions" width="150px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <template v-if="row.updateMode">
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    disabled
                    @click="confirmEdit(row)"
                    >Update</el-button
                  >
                  <el-button
                    class="cancel-btn"
                    size="small"
                    icon="el-icon-refresh"
                    type="warning"
                    disabled
                    @click="cancelEdit(row)"
                    >Cancel</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="confirmEdit(row)"
                    >Update</el-button
                  >
                  <el-button
                    class="cancel-btn"
                    size="small"
                    icon="el-icon-refresh"
                    type="warning"
                    @click="cancelEdit(row)"
                    >Cancel</el-button
                  >
                </template>
              </template>
              <template v-else>
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  @click="row.editMode = true"
                  >Edit</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDelete(row)"
                  >Delete</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddReward from "@/components/AddReward";
import UserFilter from "@/components/CompUserFilter";
import EditRewardList from "@/components/CompRewardList";
import { MessageBox, Message, Notification } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import { Loading } from "element-ui";
import {
  sendMail,
  getTrackingUsers,
  getTrackingUserMail,
  clearTrackingUserMail,
} from "@/api/inbox";

import { sendMailGroup } from "@/api/inbox";
import store from "@/store";
import {
  getMailGroupList,
  updateMailGroup,
  deleteMailGroup,
} from "@/api/mailgroup";

import convertDateString from "@/utils/get-date-string";

export default {
  name: "SendMail",
  components: { AddReward, UserFilter, EditRewardList },
  data() {
    return {
      activeNames: "1",
      mail: {
        from: store.getters.email,
        user_id: this.$route.params.user_id,
        title: "",
        content: "",
        date_begin: "",
        date_end: "",
        rewards: [],
        info: "",
      },
      mailgroup: {
        creator: store.getters.email,
        user_id: this.$route.params.user_id,
        title: "",
        content: "",
        user_filters: [],
        rewards: [],
        date_begin: "",
        date_end: "",
        info: "",
      },
      mailGroupDisplayList: [],
      mailGroupDataSync: [],

      mailTrackingDisplayList: [],
      mailTrackingDataSync: [],

      information_AutoFill: "",
      information_AutoFill_List: [
        { InfoID: "Tag: Mail Intro" },
        { InfoID: "Tag: Mail Teasing" },
        { InfoID: "(none)" },
      ],

      debugLog: "",

      dialogTagInfoVisible: false,
      TagInfoName: "",
      TagInfoStartDate: "",
      TagInfoTrackingName: "",
      TagInfoTrackingPeriod: 86400,
    };
  },
  created() {
    let beginDate = new Date();
    this.mail.date_begin = beginDate.toString();

    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);
    this.mail.date_end = endDate.toString();

    // mail group
    let beginDateGroup = new Date();
    this.mailgroup.date_begin = beginDateGroup.toString();

    let endDateGroup = new Date();
    endDateGroup.setDate(endDateGroup.getDate() + 7);
    this.mailgroup.date_end = endDateGroup.toString();

    this.getMaiGroupList();
    //this.getMaiTrackingList();
  },
  methods: {
    getMaiGroupList() {
      getMailGroupList()
        .then((response) => {
          // Example:
          //  {"data":[{"id": 10,"creator": "", "title": "","content": "","user_filters": [{"value": "","name": "","code": ""}],
          //           "rewards": [{"code": "","value": 1, "name": ""],"date_begin": 1,"date_end": 1]}

          let data = response.data;
          this.mailGroupDataSync = [];

          data.forEach((i) => {
            this.mailGroupDataSync.push(i);
          });

          this.mailGroupDisplayList = [];

          this.mailGroupDataSync.forEach((i) => {
            let displayDateBegin = new Date(i.date_begin * 1000);
            let displayDateEnd = new Date(i.date_end * 1000);
            let info = "";

            if (i.info) {
              info = i.info;
            }

            this.mailGroupDisplayList.push({
              id: Number(i.id),
              title: i.title,
              content: i.content,
              desc: "",
              editMode: false,
              updateMode: false,
              deleteMode: false,
              rewardData: i.rewards,
              filterData: i.user_filters,
              date_begin: displayDateBegin, //i.date_begin,
              date_end: displayDateEnd, //i.date_end
              info: info,
            });
          });

          this.mailGroupDisplayList.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
        })
        .catch((error) => {
          Message({
            message: "Can't fetch list items(3):" + error,
            type: "error",
            duration: 5 * 1000,
          });
          //this.loading = false;
        });
    },
    getMaiTrackingList() {
      getTrackingUsers()
        .then((response) => {
          // Example:
          //  {"ids":["a","b","c"],
          let userIDs = response.ids;

          //---------------------------------------------
          // Function load mail tracking data
          const getMailTrackingData = async (userId) => {
            let dataItem = undefined;

            dataItem = await getTrackingUserMail(userId);

            // Example:
            //  {"mails":[{mail1},{mail2}}]}

            return dataItem;
          };
          //---------------------------------------------

          let promises = [];
          let list_out = [];
          let obsolete_ids = [];

          userIDs.forEach((id) => {
            promises.push(getMailTrackingData(id));
          });

          this.mailTrackingDisplayList = [];
          this.mailTrackingDataSync = [];

          Promise.all(promises).then((response) => {
            list_out = response;

            for (let i = 0; i < list_out.length; i++) {
              // list_out[i]
              // Example:
              //  {"mails":[{mail1},{mail2}}]}

              // list_out[i].mails[0]
              //Example:
              // {mail_id: id,
              //  mail_data: {content:'a', date_begin: , date_end, from: , rewards, status, timestamp, title}
              //  }

              // -> list display
              //   {userID, title, content, date_begin, date_end, from, to, rewards, status, timestamp}

              for (let idx = 0; idx < list_out[i].mails.length; idx++) {
                let mailID = list_out[i].mails[idx].mail_id;
                let mailData = list_out[i].mails[idx].mail_data;

                if (mailData === undefined) {
                  // mail data is undefined -> ignore it
                  // delete obsolete tracking mail
                  //...
                  obsolete_ids.push({ userID: userIDs[i], mailID: mailID });
                  continue;
                }

                this.mailTrackingDataSync.push({
                  mailID: mailID,
                  userID: userIDs[i],
                  title: mailData.title,
                  content: mailData.content,
                  date_begin: mailData.date_begin,
                  date_end: mailData.date_end,
                  from: mailData.from,
                  to: userIDs[i],
                  rewards: mailData.rewards,
                  status: mailData.status,
                  timestamp: mailData.timestamp,
                });
              }
            }

            this.mailTrackingDataSync.forEach((item) => {
              let displayDateBegin = new Date(item.date_begin._seconds * 1000);
              let displayDateEnd = new Date(item.date_end._seconds * 1000);

              this.mailTrackingDisplayList.push({
                mailID: item.mailID,
                userID: item.userID,
                title: item.title,
                content: item.content,
                date_begin: displayDateBegin,
                date_end: displayDateEnd,
                from: item.from,
                to: item.userID,
                rewards: item.rewards,
                status: item.status,
                timestamp: item.timestamp,
              });
            });

            this.mailTrackingDisplayList.sort(function (a, b) {
              return b.timestamp._seconds - a.timestamp._seconds;
            });

            for (let idx_obs = 0; idx_obs < obsolete_ids.length; idx_obs++) {
              clearTrackingUserMail(
                obsolete_ids[idx_obs].userID,
                obsolete_ids[idx_obs].mailID
              )
                .then((response) => {
                  //Message({
                  //message: "delete tracking obsolete mail userID: " + obsolete_ids[idx_obs].userID + ", mailID: " + obsolete_ids[idx_obs].mailID,
                  //type: "success",
                  //duration: 5 * 1000
                  //});
                })
                .catch((error) => {
                  Message({
                    message: "Can't delete obsolete mail " + error,
                    type: "error",
                    duration: 5 * 1000,
                  });
                });
            }
          });
        })
        .catch((error) => {
          Message({
            message: "Can't fetch list items: " + error,
            type: "error",
            duration: 5 * 1000,
          });
          //this.loading = false;
        });
    },
    OnChangeBeginDate(event) {
      let date = new Date(this.mail.date_begin);
      let timestamp = date.getTime() / 1000;

      this.debugLog =
        event + " -> " + this.mail.date_begin + " -> " + timestamp;
    },
    OnChangeEndDate(event) {
      let date = new Date(this.mail.date_end);
      let timestamp = date.getTime() / 1000;

      this.debugLog = event + " -> " + this.mail.date_end + " -> " + timestamp;
    },
    OnChangeGroupBeginDate(event) {
      let date = new Date(this.mailgroup.date_begin);
      let timestamp = date.getTime() / 1000;

      this.debugLog =
        event + " -> " + this.mailgroup.date_begin + " -> " + timestamp;
    },
    OnChangeGroupEndDate(event) {
      let date = new Date(this.mailgroup.date_end);
      let timestamp = date.getTime() / 1000;

      this.debugLog =
        event + " -> " + this.mailgroup.date_end + " -> " + timestamp;
    },
    CallbackFillterData(filterData) {
      this.mailgroup.user_filters = [];
      this.mailgroup.user_filters = filterData;

      return;
    },
    CallbackRewadListChangeData(callbackData) {
      var id = callbackData.id;
      var rewardData = callbackData.data;

      /*
      for (let i = 0; i < rewardData.length; i++) {
        Message({
          message:
            "Callback Reward data id:" +
            id +
            ", name: " +
            rewardData[i].name +
            ", code:" +
            rewardData[i].code +
            ", value:" +
            rewardData[i].value,
          type: "error",
          duration: 5 * 1000
        });
      }*/
    },
    onSubmit() {
      if (
        isStringEmpty(this.mail.user_id) ||
        this.mail.user_id.charAt(0) === ":"
      ) {
        Message({
          message: "Receiver id is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.mail.title)) {
        Message({
          message: "Title is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.mail.content)) {
        Message({
          message: "Content is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      MessageBox.confirm(
        "Do you confirm send mail to user?",
        "Confirm send mail",
        {
          confirmButtonText: "Send",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let loadingInstance = Loading.service({
          lock: true,
          text: "Sending mail...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        let dateBegin = new Date(this.mail.date_begin);
        let timestampBegin = dateBegin.getTime() / 1000;

        let dateEnd = new Date(this.mail.date_end);
        let timestampEnd = dateEnd.getTime() / 1000;

        let mail_info = this.mail.info;

        //------------------------------------------------------------------------------------
        // Send mail with data
        const sendMailData = async (
          userID,
          from,
          title,
          content,
          rewards,
          tsBegin,
          tsEnd
        ) => {
          let result = undefined;

          let sendData = {
            user_id: userID,
            from: from,
            title: title,
            content: content,
            rewards: rewards,
            date_begin: tsBegin,
            date_end: tsEnd,
          };

          if (mail_info) {
            sendData.info = mail_info;
          }

          await sendMail(sendData)
            .then((response) => {
              if (response) {
                if (response.send_inbox && response.send_inbox.length > 0) {
                  result = "success";
                } else {
                  result = "failed: " + JSON.stringify(response);
                }
              } else {
                result = "failed: invalid response";
              }
            })
            .catch((error) => {
              result = "failed: " + error;
            });

          return result;
        };
        //------------------------------------------------------------------------------------

        if (this.mail.user_id[this.mail.user_id.length - 1] === ";") {
          this.mail.user_id = this.mail.user_id.slice(0, -1);
        }

        this.mail.user_id = this.mail.user_id.trim();

        let userIDs = this.mail.user_id.split(";");
        const promises = [];
        let list_out = [];

        if (userIDs.length > 0) {
          let isValid = true;

          for (let i = 0; i < userIDs.length; i++) {
            const index = userIDs.indexOf(userIDs[i], i + 1);
            if (index > -1) {
              Message({
                message:
                  'Duplitcate UserID "' +
                  userIDs[i] +
                  '" in the "To user" field',
                type: "error",
                duration: 20 * 1000,
              });

              isValid = false;
              break;
            }
          }

          if (isValid) {
            for (let i = 0; i < userIDs.length; i++) {
              promises.push(
                sendMailData(
                  userIDs[i],
                  this.mail.from,
                  this.mail.title,
                  this.mail.content,
                  this.mail.rewards,
                  timestampBegin,
                  timestampEnd
                )
              );
            }

            Promise.all(promises).then((response) => {
              list_out = response;

              if (userIDs.length != list_out.length) {
                Message({
                  message:
                    "User id length not match result length " +
                    userIDs.length +
                    "!=" +
                    list_out.length,
                  type: "error",
                  duration: 20 * 1000,
                });
              } else {
                let log_response_success = "";
                let log_response_failed = "";

                let count_success = 0;
                let count_failed = 0;

                for (let i = 0; i < list_out.length; i++) {
                  if (list_out[i] === "success") {
                    if (i > 0) {
                      log_response_success += "<br>";
                    }

                    log_response_success += userIDs[i] + "->" + list_out[i];
                    count_success = count_success + 1;
                  } else {
                    if (i > 0) {
                      log_response_failed += "<br>";
                    }

                    log_response_failed += userIDs[i] + "->" + list_out[i];
                    count_failed = count_failed + 1;
                  }
                }

                if (log_response_success.length > 0) {
                  Message({
                    dangerouslyUseHTMLString: true,
                    message:
                      "Finished Sending SUCCESS: " +
                      count_success +
                      "<br>" +
                      log_response_success,
                    type: "success",
                    duration: 20 * 1000,
                  });
                }

                if (log_response_failed.length > 0) {
                  Notification({
                    dangerouslyUseHTMLString: true,
                    message:
                      "Finished Sending FAILED: " +
                      count_failed +
                      "<br>" +
                      log_response_failed,
                    type: "error",
                    duration: 60 * 1000,
                  });
                } else {
                  // clear data
                  this.mail.title = "";
                  this.mail.content = "";
                }
              }

              loadingInstance.close();
            });
          } else {
            loadingInstance.close();
          }
        } else {
          loadingInstance.close();
        }
      });
    },
    onSubmitGroup() {
      if (isStringEmpty(this.mailgroup.title)) {
        Message({
          message: "Title is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.mailgroup.content)) {
        Message({
          message: "Content is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      MessageBox.confirm(
        "Do you confirm send mailgroup to user?",
        "Confirm send mailgroup",
        {
          confirmButtonText: "Send",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let loadingInstance = Loading.service({
          lock: true,
          text: "Sending mailgroup...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        ////{creator,title,content,user_filters,rewards,date_begin,date_end}

        let dateBegin = new Date(this.mailgroup.date_begin);
        let timestampBegin = dateBegin.getTime() / 1000;

        let dateEnd = new Date(this.mailgroup.date_end);
        let timestampEnd = dateEnd.getTime() / 1000;

        let mail_info = this.mailgroup.info;

        let sendDataGroup = {
          creator: this.mailgroup.creator,
          title: this.mailgroup.title,
          content: this.mailgroup.content,
          user_filters: this.mailgroup.user_filters,
          rewards: this.mailgroup.rewards,
          date_begin: timestampBegin,
          date_end: timestampEnd,
        };

        if (mail_info) {
          sendDataGroup.info = mail_info;
        }

        //{creator,title,content,user_filters,rewards,date_begin,date_end}
        sendMailGroup(sendDataGroup)
          .then((response) => {
            loadingInstance.close();
            Message({
              message: response,
              type: "success",
              duration: 20 * 1000,
            });

            // clear data
            this.mailgroup.title = "";
            this.mailgroup.content = "";

            this.getMaiGroupList();
          })
          .catch((error) => {
            loadingInstance.close();
          });
      });
    },
    handleClick() {},
    confirmEdit(row) {
      if (isStringEmpty(row.title)) {
        Message({
          message: "Title is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(row.content)) {
        Message({
          message: "Content is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      row.updateMode = true;

      //map(mailGroupDisplayList, this.mailGroupDataSync[i])
      //    {
      //     id: i.id,
      //      title: i.title,
      //      content: i.content,
      //      desc: '',
      //      editMode: false,
      //      updateMode: false,
      //      deleteMode: false,
      //      rewardData: i.rewards,
      //      filterData: i.user_filters
      //    }

      let modifyItem;

      try {
        for (let i = 0; i < this.mailGroupDataSync.length; i++) {
          if (this.mailGroupDataSync[i].id == row.id) {
            this.mailGroupDataSync[i].title = row.title;
            this.mailGroupDataSync[i].content = row.content;
            this.mailGroupDataSync[i].info = row.info;

            var beginTm = new Date(row.date_begin).getTime() / 1000;
            this.mailGroupDataSync[i].date_begin = beginTm;

            var endTm = new Date(row.date_end).getTime() / 1000;
            this.mailGroupDataSync[i].date_end = endTm;

            for (let j = 0; j < this.mailGroupDataSync[i].rewards.length; j++) {
              for (let k = 0; k < row.rewardData.length; k++) {
                if (
                  this.mailGroupDataSync[i].rewards[j].name ==
                  row.rewardData[k].name
                ) {
                  this.mailGroupDataSync[i].rewards[j].value =
                    row.rewardData[k].value;
                }
              }
            }

            for (
              let j = 0;
              j < this.mailGroupDataSync[i].user_filters.length;
              j++
            ) {
              for (let k = 0; k < row.filterData.length; k++) {
                if (
                  this.mailGroupDataSync[i].user_filters[j].name ==
                  row.filterData[k].name
                ) {
                  this.mailGroupDataSync[i].user_filters[j].value =
                    row.filterData[k].value;
                }
              }
            }

            modifyItem = this.mailGroupDataSync[i];
            break;
          }
        }

        this.mailGroupDisplayList = [];

        this.mailGroupDataSync.forEach((i) => {
          let displayDateBegin = new Date(i.date_begin * 1000);
          let displayDateEnd = new Date(i.date_end * 1000);

          this.mailGroupDisplayList.push({
            id: Number(i.id),
            title: i.title,
            content: i.content,
            desc: "",
            editMode: false,
            updateMode: false,
            deleteMode: false,
            rewardData: i.rewards,
            filterData: i.user_filters,
            date_begin: displayDateBegin, //i.date_begin,
            date_end: displayDateEnd, //i.date_end
            info: i.info,
          });
        });

        this.mailGroupDisplayList.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
      } catch (err) {
        Message({
          message: "Can't edit mail group data",
          type: "error",
          duration: 5 * 1000,
        });
      }

      updateMailGroup(modifyItem)
        .then((response) => {
          Message({
            message: response,
            type: "success",
            duration: 5 * 1000,
          });

          row.updateMode = false;
          row.editMode = false;
        })
        .catch((error) => {
          row.updateMode = false;
          row.editMode = false;
        });
    },
    cancelEdit(row) {
      row.updateMode = false;
      row.editMode = false;
    },
    onDelete(row) {
      MessageBox.confirm(
        "Do you confirm delete item: " + row.title + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        row.deleteMode = true;
        deleteMailGroup({ id: row.id })
          .then((response) => {
            Message({
              message: response,
              type: "success",
              duration: 5 * 1000,
            });
            this.mailGroupDisplayList = this.mailGroupDisplayList.filter(
              function (ele) {
                return ele.id != row.id;
              }
            );
          })
          .catch((error) => {
            row.deleteMode = false;
          });
      });
    },
    //Action clear mail tracking
    onDeleteMailTracking(row) {
      MessageBox.confirm(
        "Do you confirm clear tracking item: " + row.title + "?",
        "Confirm clear",
        {
          confirmButtonText: "Clear",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        row.deleteMode = true;
        clearTrackingUserMail(row.userID, row.mailID)
          .then((response) => {
            this.mailTrackingDisplayList = this.mailTrackingDisplayList.filter(
              function (ele) {
                return ele.mailID != row.mailID;
              }
            );
          })
          .catch((error) => {
            row.deleteMode = false;
          });
      });
    },
    getDateString(d) {
      return convertDateString(d);
    },
    clearMailInfo() {
      if (this.activeNames === "1") {
        // tab send mail
        this.mail.info = "";
      } else if (this.activeNames === "3") {
        // tab send group
        this.mailgroup.info = "";
      }
    },
    onInformationAutoFillChange(event) {
      if (event === "(none)") {
        this.clearMailInfo();
        return;
      }

      for (let i = 0; i < this.information_AutoFill_List.length; i++) {
        if (this.information_AutoFill_List[i].InfoID === event) {
          if (event === "Tag: Mail Intro") {
            this.TagInfoName = "EventIntro";

            if (!this.TagInfoStartDate || this.TagInfoStartDate.length <= 0) {
              let date = new Date();
              this.TagInfoStartDate = date;
            }

            this.dialogTagInfoVisible = true;
            break;
          } else if (event === "Tag: Mail Teasing") {
            this.TagInfoName = "EventTeasing";

            if (!this.TagInfoStartDate || this.TagInfoStartDate.length <= 0) {
              let date = new Date();
              this.TagInfoStartDate = date;
            }

            this.dialogTagInfoVisible = true;
            break;
          }
        }
      }
    },
    OnChangeInformationBeginDate(event) {
      let date = new Date(this.TagInfoStartDate);
      let timestamp = date.getTime() / 1000;

      this.debugLog = event + " -> " + this.date + " -> " + timestamp;
    },
    OnInformationCancel() {
      this.dialogTagInfoVisible = false;
    },
    OnInformationConfirm() {
      this.dialogTagInfoVisible = false;

      let info = "";
      let timestampStartDate = 0;
      let tagInfoTrackingName = "";
      let tagInfoTrackingPeriod = 0;
      if (
        this.TagInfoName === "EventIntro" ||
        this.TagInfoName === "EventTeasing"
      ) {
        if (this.TagInfoStartDate) {
          let date = new Date(this.TagInfoStartDate);
          timestampStartDate = Math.floor(date.getTime() / 1000);
        }

        if (this.TagInfoTrackingName.length > 0) {
          const reg = /^[a-z0-9_]+$/;
          let isvalid = reg.test(this.TagInfoTrackingName);

          if (!isvalid) {
            Message({
              message:
                "The 'Tracking Mail Name' contains alpha in lower case (a-z), number (0-9) and underscore( _ ) only!",
              type: "error",
              duration: 5 * 1000,
            });

            this.dialogTagInfoVisible = true;
            return;
          }

          tagInfoTrackingName = this.TagInfoTrackingName;
        }

        if (Number(this.TagInfoTrackingPeriod) > 0) {
          tagInfoTrackingPeriod = Number(this.TagInfoTrackingPeriod);
        }
      }

      // create a json string
      if (this.TagInfoName.length > 0) {
        info = "{";
        info += '"Tag":' + '"' + this.TagInfoName + '"';

        if (timestampStartDate > 0) {
          info += ",";
          info += '"StartDate":' + timestampStartDate;
        }

        if (tagInfoTrackingName.length > 0 > 0) {
          info += ",";
          info += '"TrackingName":' + '"' + tagInfoTrackingName + '"';
        }

        if (tagInfoTrackingPeriod > 0) {
          info += ",";
          info += '"TrackingPeriod":' + tagInfoTrackingPeriod;
        }

        info += "}";
      }

      if (this.activeNames === "1") {
        // tab send mail
        this.mail.info = info;
      } else if (this.activeNames === "3") {
        // tab send group
        this.mailgroup.info = info;
      }
    },
  },
};
</script>
